<ng-template [nzModalTitle]>
  <div class="component-header center-vertical flex-space-between">
    <div class="center-vertical">
      <div class="modal-close" (click)="closeDialog()">
        <i nz-icon nzType="close"></i>
      </div>
      <div class="modal-title" style="margin-left: 12px;">Filters</div>
    </div>
    <div class="btn-action right30">
      <button nz-button nzType="default" nzDanger (click)="onBtnResetFilter()" class="right15">Reset</button>
      <button nz-button nzType="primary" nzGhost (click)="onBtnSaveFilter()">
        <i nz-icon nzType="filter" nzTheme="fill"></i> {{ filterInfo?.id ? 'Update' : 'Save' }} Filter
      </button>
      <ng-container>
        <nz-divider nzType="vertical"></nz-divider>
        <button nz-button nzType="primary" (click)="onBtnFilters()">Apply</button>
      </ng-container>
    </div>
  </div>
</ng-template>
<div>
  <form [formGroup]="formInput" nz-form>
    <ng-container *ngFor="let key of ['name']">
      <div class="form-label">Filter's name<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <div nz-row [nzGutter]="{md:32}" class="form-group">
        <div nz-col nzSm="6" nzMd="6" class="form-item" [ngClass]="key">
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input filter's name!">
              <input nz-input [formControlName]="key" [placeholder]="getPlaceHolder(key)" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </ng-container>
    <div class="separator h bottom10"></div>

    <div class="form-label">Customers, Carrier, Location</div>
    <div nz-row [nzGutter]="{md:32}" class="form-group">
      <div nz-col nzSm="6" nzMd="6" *ngFor="let key of ['clientId']" class="form-item" [ngClass]="key" #customerFilter>
        <div>
          <div class="form-label-v2">{{getLabel(key)}}</div>
          <div class="flex" style="min-width: 100px;">
            <div>
              <nz-select formControlName="clientFilterType"  (ngModelChange)="onChange('clientFilterType', $event)" style="width: 100%">
                <nz-option nzLabel="Include" nzValue="include"></nz-option>
                <nz-option nzLabel="Exclude" nzValue="exclude"></nz-option>
              </nz-select>
            </div>
            <select-by-searching
              [version]="2"
              nzMode="multiple"
              [apiUrl]="getApiListDataForFilter(key)"
              [formControlName]="key"
              [showSubClient]="true"
              [placeholder]="getPlaceHolder(key)"
              dropdownClassName="dispatch-filter-customer"
              style="width: calc(100% - 60px); max-width: 170px;"
              (ngModelChange)="onChange($event, key)"
            >
            </select-by-searching>
          </div>
        </div>
      </div>

      <div nz-col nzSm="6" nzMd="6" *ngFor="let key of ['carrierId']" class="form-item" [ngClass]="key">
        <div>
          <div class="form-label-v2">{{getLabel(key)}}</div>
          <div class="flex" style="min-width: 100px;">
            <div>
              <nz-select formControlName="carrierFilterType"  (ngModelChange)="onChange('carrierFilterType', $event)" style="width: 100%">
                <nz-option nzLabel="Include" nzValue="include"></nz-option>
                <nz-option nzLabel="Exclude" nzValue="exclude"></nz-option>
              </nz-select>
            </div>
            <nz-select
              nzBackdrop="true"
              [nzMode]="shouldSelectMultipleCarrier ? 'multiple' : 'default'"
              nzAllowClear
              nzShowSearch
              [formControlName]="key"
              [nzLoading]="isFetchingCarrier"
              [nzPlaceHolder]="getPlaceHolder(key)"
              nzDropdownClassName="dispatch-filter-carrier"
              style="width: 100%"
              (ngModelChange)="onChangeCarrier($event, key); onChange($event, key)"
            >
              <nz-option-group nzLabel="Filter by assignment status">
                <nz-option nzValue="no" nzLabel="Not assigned yet"></nz-option>
                <nz-option nzValue="yes" nzLabel="Already assigned"></nz-option>
              </nz-option-group>
              <nz-option-group nzLabel="Filter by specific carrier">
                <nz-option
                  nzCustomContent
                  *ngFor="let item of listCarriers"
                  [nzValue]="item.id"
                  [nzLabel]="getCarrierNameWithMCandDOT(item)"
                >
                  <div>{{getCarrierName(item)}}</div>
                  <div *ngIf="item?.basicInfo?.mc" class="sub-info">MC: {{getCarrierMC(item)}}</div>
                  <div *ngIf="item?.basicInfo?.dot" class="sub-info">DOT: {{getCarrierDOT(item)}}</div>
                </nz-option>
              </nz-option-group>
            </nz-select>
          </div>
        </div>
      </div>

      <div nz-col nzSm="6" nzMd="6" *ngFor="let key of ['pickupLocation']" class="form-item" [ngClass]="key">
        <div class="form-label-v2">{{getLabel(key)}}</div>
        <div class="date-picker-row flex">
          <ng-container class="flex location">
            <nz-select nzBackdrop="true" 
              nzShowSearch nzAllowClear
              style="width: 100%; overflow: auto"
              [nzPlaceHolder]="getPlaceHolder(key)" [formControlName]="key"
              nzDropdownClassName="dispatch-filter-dispatcher"
              (nzOnSearch)="onLocationSearch(key, $event)">
              <cdk-virtual-scroll-viewport itemSize="50" class="cdk-virtual-scroll-element">
                <nz-option-group nzLabel="Saved Location">
                  <nz-option *ngFor="let wh of listFilterWarehouses" [nzLabel]="wh.name" [nzValue]="wh"></nz-option>
                </nz-option-group>
                <nz-option-group nzLabel="Address">
                  <nz-option *ngFor="let addr of listRegions" [nzLabel]="addr.formatedAddress" [nzValue]="addr"></nz-option>
                  <nz-option *ngFor="let state of listFilterStates" [nzLabel]="getStateDesc(state.code)" [nzValue]="state.code"></nz-option>
                </nz-option-group>
              </cdk-virtual-scroll-viewport>
            </nz-select>
            <nz-select nzBackdrop="true" 
              [(ngModel)]="selectedCountryPick" 
              [formControlName]="'pickupCountry'"
              style="width: 70px;" class="country" nz-tooltip
              nzTooltipTitle="Select country">
              <nz-option *ngFor="let item of country" [nzLabel]="item.code" [nzValue]="item.code"></nz-option>
            </nz-select>
          </ng-container>
        </div>
      </div>

      <div nz-col nzSm="6" nzMd="6" *ngFor="let key of ['dropoffLocation']" class="form-item" [ngClass]="key">
        <div class="form-label-v2">{{getLabel(key)}}</div>
        <div class="date-picker-row flex">
          <ng-container class="flex location">
            <nz-select nzBackdrop="true" 
              style="width: 100%; overflow: auto"
              nzShowSearch nzAllowClear
              [nzPlaceHolder]="getPlaceHolder(key)" [formControlName]="key"
              nzDropdownClassName="dispatch-filter-dispatcher"
              (nzOnSearch)="onLocationSearch(key, $event)">
              <cdk-virtual-scroll-viewport itemSize="50" class="cdk-virtual-scroll-element">
                <nz-option-group nzLabel="Saved Location">
                  <nz-option *ngFor="let wh of listFilterWarehouses" [nzLabel]="wh.name" [nzValue]="wh"></nz-option>
                </nz-option-group>
                <nz-option-group nzLabel="Address">
                  <nz-option *ngFor="let addr of listRegions" [nzLabel]="addr.formatedAddress" [nzValue]="addr"></nz-option>
                  <nz-option *ngFor="let state of listFilterStates" [nzLabel]="getStateDesc(state.code)" [nzValue]="state.code"></nz-option>
                </nz-option-group>
              </cdk-virtual-scroll-viewport>
            </nz-select>
            <nz-select nzBackdrop="true" 
              [(ngModel)]="selectedCountryDrop" 
              [formControlName]="'dropoffCountry'"
              style="width: 70px;" class="country" nz-tooltip
              nzTooltipTitle="Select country">
              <nz-option *ngFor="let item of country" [nzLabel]="item.code" [nzValue]="item.code"></nz-option>
            </nz-select>
          </ng-container>
        </div>
      </div>

    </div>
    <div class="separator h bottom10"></div>
    <div class="form-label flex">
      Date Range
      <nz-radio-group [(ngModel)]="dateRangePreset" [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangePreset($event)">
        <label nz-radio [nzValue]="3" class="left20">Next 3 days</label>
        <label nz-radio [nzValue]="7">Next 7 days</label>
      </nz-radio-group>
    </div>
    <div nz-row [nzGutter]="{md:32}" class="form-group">
      <div *ngFor="let key of ['fromDate']" nz-col nzSm="12" nzMd="8" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>
        <nz-input-group>
          <nz-date-picker
            style="width: 65%"
            [formControlName]="key"
            nzFormat="yyyy-MM-dd"
            (ngModelChange)="onChange($event, key)"
          >
          </nz-date-picker>
          <ng-container *ngFor="let key1 of ['fromTimezone']">
            <nz-select nzBackdrop="true" style="width: 35%"
            [nzPlaceHolder]="getPlaceHolder(key1)" [formControlName]="key1" 
            nzDropdownClassName="timezone"
            (ngModelChange)="onChange($event, key1)"
            >
              <nz-option *ngFor="let timezone of listTimezones" [nzLabel]="timezone" [nzValue]="timezone"></nz-option>
           </nz-select>
          </ng-container>
        </nz-input-group>
      </div>
      <div *ngFor="let key of ['toDate']" nz-col nzSm="12" nzMd="8" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>
        <nz-input-group>
          <nz-date-picker
            style="width: 65%"
            [formControlName]="key"
            nzFormat="yyyy-MM-dd"
            (ngModelChange)="onChange($event, key)"
          >
          </nz-date-picker>
          <ng-container *ngFor="let key1 of ['toTimezone']">
            <nz-select nzBackdrop="true" style="width: 35%;"
              [nzPlaceHolder]="getPlaceHolder(key1)" [formControlName]="key1" 
              nzDropdownClassName="timezone"
              (ngModelChange)="onChange($event, key1)"
              >
              <nz-option *ngFor="let timezone of listTimezones" [nzLabel]="timezone" [nzValue]="timezone"></nz-option>
            </nz-select>
          </ng-container>
        </nz-input-group>
      </div>
    </div>
    <div class="separator h bottom10"></div>
    <div class="form-label" style="display: flex;">Equipment, Shipment Type <div *ngIf="urlMyLoad != 'my_load'" style="margin-left: 3px;"> & Dispatcher</div></div>
    <div nz-row [nzGutter]="{md:32}" class="form-group">
      <div *ngFor="let key of ['vehicleType']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-form-item>
          <nz-form-control>
            <div vehicle-selector [value]="getVehicleType()" (valueChange)="onChange($event, key)" [quoting]="false" [withOptions]="false"></div>
            <!-- <app-form-equipments [formControlName]="key" (ngModelChange)="onChange($event, key)"></app-form-equipments> -->
          </nz-form-control>
        </nz-form-item>
      </div>
      <div *ngFor="let key of ['shipmentType']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-select
          nzBackdrop="true"
          nzAllowClear
          nzShowSearch
          style="width: 100%"
          [formControlName]="key"
          [nzPlaceHolder]="getPlaceHolder(key)"
          (ngModelChange)="onChange($event, key)"
        >
          <nz-option *ngFor="let item of listShipmentTypes" [nzValue]="item" [nzLabel]="item"> </nz-option>
        </nz-select>
      </div>
      <div *ngFor="let key of ['picId']" nz-col nzSm="6" nzMd="6" class="form-item" class="{{'display-dispatcher-' + setShowDispatcher()}}">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-select
          nzBackdrop="true"
          nzAllowClear
          nzShowSearch
          style="width: 100%"
          [formControlName]="key"
          [nzLoading]="isFetchingCarrier"
          [nzPlaceHolder]="getPlaceHolder(key)"
          nzDropdownClassName="dispatch-filter-dispatcher"
          (ngModelChange)="onChangeDispatcher($event, key)"
        >
          <nz-option-group nzLabel="Filter by assignment status">
            <nz-option nzValue="no" nzLabel="Not assigned yet"></nz-option>
            <nz-option nzValue="yes" nzLabel="Already assigned"></nz-option>
          </nz-option-group>
          <nz-option-group nzLabel="Filter by apecific dispatcher">
            <nz-option
              *ngFor="let item of listDispatcherForDropdown"
              [nzValue]="item.id"
              [nzLabel]="dispatcherName(item)"
            >
            </nz-option>
          </nz-option-group>
        </nz-select>
      </div>
    </div>
    <div class="separator h bottom10"></div>
    <div class="form-label">Sales Rep</div>
    <div nz-row [nzGutter]="{md:32}" class="form-group">
      <div *ngFor="let key of ['carrierSaleRepId']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>
        <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
          [formControlName]="key" [nzLoading]="isFetchingCarrierSaleRep"
          [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-carrier-sale-rep"
           style="width: 100%;" (ngModelChange)="onChange($event, key)">
          <nz-option-group nzLabel="Filter by specific carrier sales rep">
            <nz-option *ngFor="let item of listCarrierSales"
              [nzValue]="item.id" [nzLabel]="getFullName(item)">
            </nz-option>
          </nz-option-group>
        </nz-select>
      </div>
      <div *ngFor="let key of ['clientSaleRepId']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-select nzAllowClear nzShowSearch
          [formControlName]="key" [nzLoading]="isFetchingClientSaleRep"
          [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-carrier-sale-rep"
          style="width: 100%;" (ngModelChange)="onChange($event, key)">
          <nz-option-group nzLabel="Filter by specific client seller">
            <nz-option *ngFor="let item of listClientSales"
              [nzValue]="item.id" [nzLabel]="getFullName(item)">
            </nz-option>
          </nz-option-group>
        </nz-select>
      </div>
      <div *ngFor="let key of ['clientServiceUserId']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-select nzAllowClear nzShowSearch
          [formControlName]="key" [nzLoading]="isFetchingClientSaleRep"
          [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-carrier-sale-rep"
          style="width: 100%;" (ngModelChange)="onChange($event, key)">
          <nz-option-group nzLabel="Filter by specific client account owner">
            <nz-option *ngFor="let item of listClientSales"
              [nzValue]="item.id" [nzLabel]="getFullName(item)">
            </nz-option>
          </nz-option-group>
        </nz-select>
      </div>
      <div *ngFor="let key of ['clientServiceRepUserId']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-select nzAllowClear nzShowSearch
                   [formControlName]="key" [nzLoading]="isFetchingClientSaleRep"
                   [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-carrier-sale-rep"
                   style="width: 100%;" (ngModelChange)="onChange($event, key)">
          <nz-option-group nzLabel="Filter by specific client service rep">
            <nz-option *ngFor="let item of listClientSales"
                       [nzValue]="item.id" [nzLabel]="getFullName(item)">
            </nz-option>
          </nz-option-group>
        </nz-select>
      </div>
    </div>
    <div class="separator h bottom10"></div>
    <div class="form-label">Tracking Tasks</div>
    <div *ngFor="let key of ['archivedTrackingTask']" nz-col nzSm="6" nzMd="6" class="form-item">
      <div class="form-label-v2">{{getLabel(key)}}</div>

      <nz-select nzAllowClear nzShowSearch
        [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)"
        style="width: 100%;" (ngModelChange)="onChange($event, key)">
        <nz-option [nzLabel]="'Show tasks'" [nzValue]="false"></nz-option>
        <nz-option [nzLabel]="'Show tasks archived'" [nzValue]="true"></nz-option>
      </nz-select>
    </div>

    <div class="separator h bottom10"></div>
    <div class="form-label">Others</div>
    <div nz-row [nzGutter]="{md:32}" class="form-group">
      <div class="form-item" style="margin-left: 16px;">
        <div class="form-label-v2"></div>
        <div style="padding-top: 5px" class="{{'display-dispatcher-' + setShowDispatcher()}}">
          <label nz-checkbox [(ngModel)]="needDispatcherChecked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckboxNeedDispatcher($event)">Need Dispatcher</label>
        </div>
      </div>

      <div class="form-item">
        <div class="form-label-v2"></div>
        <div style="padding-top: 5px">
          <label nz-checkbox [(ngModel)]="needCarrierChecked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckboxNeedCarrier($event)">Need Carrier</label>
        </div>
      </div>
      <div class="form-item">
        <div class="form-label-v2"></div>
        <div style="padding-top: 5px">
          <label nz-checkbox [(ngModel)]="needDriverChecked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckboxNeedDriver($event)">Need Driver</label>
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="form-item">
        <div class="form-label-v2"></div>
        <div style="padding-top: 5px">
          <label nz-checkbox [(ngModel)]="needPODChecked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckboxNeedPOD($event)">Need POD</label>
        </div>
      </div>
      <div class="form-item">
        <div class="form-label-v2"></div>
        <div style="padding-top: 5px">
          <label nz-checkbox [(ngModel)]="isWalmartScheduledLessThanDuration" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckboxWalmartFilter($event)">Walmart 4-hours</label>
        </div>
      </div>
    </div>
    <div *ngIf="lastUpdated">
      <div class="last-updated">Request ID: {{lastUpdatedTime}} [{{requestId}}]</div>
    </div>
  </form>
</div>
