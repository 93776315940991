import { ResponseWhenBy_User } from "@wearewarp/types/rest-api/common";

export function getLinkDashboardForEnitty(entity: string, id: string): string {
  switch (entity) {
    case 'users':
    case 'drivers':
    case 'carriers':
    case 'clients':
      return `dashboard/${entity}/${id}`;
    default:
      return '';
  }
}

export function getLinkDashboard(user: ResponseWhenBy_User): string {
  return getLinkDashboardForEnitty(user.entity, user.id);
}